<template>
  <div class="home-wrap">
    <div class="header">
      <div class="title">
        <img
          src="https://songcw-sit.oss-cn-shanghai.aliyuncs.com/20230628/JQfDKc3OnLzxP0iGghK9Zy65hgvz3c8x.png"
          alt=""
        />
        <p>催收工作台</p>
      </div>
      <div class="user-info">
        <div class="info">
          <div>{{ userInfo.merchantName || "-" }}</div>
          <p>
            {{ userInfo.realName || "-" }} {{ stringHypose(userInfo.username) }}
          </p>
        </div>
        <div class="logout">
          <img
            src="https://songcw-sit.oss-cn-shanghai.aliyuncs.com/20230628/YUPGbH2krl2ApLC05Paj14z16iLY0mFW.png"
            alt=""
            @click="logout()"
          />
        </div>
      </div>
    </div>

    <div class="collect-tips">
      <div class="block-title">
        催收提醒
        <div class="filter-mode">
          <div :class="type == 1 ? 'active' : ''" @click="type = 1">按金额</div>
          <div :class="type == 2 ? 'active' : ''" @click="type = 2">按数量</div>
        </div>
      </div>
      <div class="tips">
        <Item
          v-for="(item, index) in collStatList"
          :key="index"
          :item="item"
          :type="type"
        />
      </div>
      <van-skeleton v-if="pending" :row="4" />
      <div class="none-data" v-if="!pending && !collStatList.length">
        暂无数据
      </div>
      <div
        v-if="collStatListMore"
        class="view-all"
        @click="
          $router.push({
            path: '/notice',
          })
        "
      >
        <span>查看全部</span>
        <van-icon class="ml-4" name="arrow" color="#B6B6B6" size="0.373rem" />
      </div>
    </div>

    <div class="workbench">
      <div class="block-title">催收工作台</div>
      <div class="wk-list">
        <div class="wk-item" @click="$router.push(`/overdueCustomer`)">
          <img
            src="https://songcw-sit.oss-cn-shanghai.aliyuncs.com/20230628/ZIqPJrdFgWq9JhdLf90DaO0sZBRU9xJq.png"
          />
          <p>逾期客户</p>
        </div>
        <div class="wk-item" @click="$router.push(`/urgeChargeRecordManage`)">
          <img
            src="https://songcw-sit.oss-cn-shanghai.aliyuncs.com/20230628/FZLx65SShjlTU00zaQwxYCC1k3EhauqI.png"
          />
          <p>催收记录</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { stringHypose } from "@/utils/tool";
import { tologin } from "@/utils/app";

import {
  logoutToken,
  collectionReminderStatisticsPost,
  dashboardCollectionReminder,
} from "@/api";

export default {
  name: "HomeIndex",
  components: {
    Item: () => import("./notice/components/Item.vue"),
  },
  data() {
    return {
      pending: false,
      type: 1,
      collStatList: [],
      collStatListMore: false,

      //
    };
  },
  computed: {
    ...mapState(["userInfo"]),

    //
  },
  mounted() {
    this.getRelationshipList();
    this.getCollectionResultList();
    this.getRepaymentPossibilityEnum();
    this.getLegalSuggestionEnum();

    this.getStatistics();
  },
  methods: {
    ...mapActions(["setUserInfo"]),
    ...mapActions("enums", [
      "getRelationshipList",
      "getCollectionResultList",
      "getLegalSuggestionEnum",
      "getRepaymentPossibilityEnum",
    ]),
    stringHypose,

    // 登出
    async logout() {
      try {
        await this.$dialog.confirm({
          title: "提示",
          message: "您是否确认登出？",
        });

        this.$toast.loading({ duration: 0, forbidClick: true });

        await logoutToken();

        setImmediate(() => this.$toast("已登出"));

        tologin();

        //
      } catch (err) {
        if (err == "cancel") return;
        setImmediate(() => this.$toast(err));
      } finally {
        this.$toast.clear();
      }

      //
    },

    // 提醒统计
    async getStatistics() {
      try {
        this.pending = true;

        const data = await dashboardCollectionReminder();
        const list = data.filter((it) => it.status == "Y").slice(0, 4);

        const promiseArr = list.map((item) =>
          (async () => {
            const o = await collectionReminderStatisticsPost({
              day: item.startDay,
              startDay: item.startDay,
              endDay: item.endDay,
              status: item.collectionReminderType,
            });
            item.amount = o.amount;
            item.count = o.count;
          })()
        );

        await Promise.all(promiseArr);

        this.collStatListMore = !!data.length;
        this.collStatList = list;

        //
      } catch (err) {
        setImmediate(() => this.$errMsg(err));
      } finally {
        this.pending = false;
        this.$toast.clear();
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.home-wrap {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background-color: #f5f7f9;
  .header {
    height: 392px;
    width: 100vw;
    background-image: url("https://songcw-sit.oss-cn-shanghai.aliyuncs.com/20230628/d183yCtVzLsdJqewYNu4vBpNo8weVUSA.png");
    background-size: 750px 240px;
    background-position: 0 0;
    .title {
      margin-top: 56px;
      padding: 0 24px;
      img {
        height: 48px;
        width: 48px;
      }
      p {
        font-size: 36px;
        height: 36px;
        margin-top: 16px;
        line-height: 36px;
        font-weight: bold;
        color: #1890ff;
      }
    }
    .user-info {
      height: 180px;
      margin-top: 56px;
      display: flex;
      justify-content: space-between;
      background: #ffffff;
      border-radius: 24px 24px 0px 0px;
      padding: 0 24px;
      .info {
        flex: 1;
        display: flex;
        flex-direction: column;
        div {
          font-weight: 600;
          color: #333333;
          line-height: 32px;
          margin-top: 48px;
          font-size: 32px;
        }
        p {
          font-weight: 400;
          color: #333333;
          line-height: 28px;
          margin-top: 24px;
          font-size: 28px;
        }
      }
      .logout {
        display: flex;
        align-items: center;
        img {
          height: 40px;
          width: 40px;
        }
      }
    }
  }

  .collect-tips {
    width: 100%;
    margin-top: 16px;
    background: #fff;
    .filter-mode {
      display: flex;
      div {
        font-size: 28px;
        line-height: 28px;
        padding: 0 16px;
        color: #666666;
        font-weight: normal;
        &.active {
          color: #1890ff;
        }
        &:first-child {
          border-right: 1px solid #f2f5f5;
        }
      }
    }
    .tips {
      display: flex;
      padding: 0 24px;
      flex-wrap: wrap;
    }
    .view-all {
      height: 28px;
      margin: 10px auto;
      height: 28px;
      font-size: 28px;
      font-weight: 400;
      color: #666666;
      line-height: 28px;
      text-align: center;
    }
    padding-bottom: 24px;
  }

  .workbench {
    width: 100%;
    background-color: #fff;
    margin-top: 16px;
    padding-bottom: 60px;

    .wk-list {
      display: flex;
      flex-wrap: wrap;
      padding: 0 24px;
      .wk-item {
        width: 120px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 64px;
        &:first-child {
          margin-left: 0;
        }
        img {
          width: 80px;
          height: 80px;
        }
        p {
          margin-top: 16px;
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 28px;
        }
      }
    }
  }
}

</style>
